import { Component, Input, OnInit } from '@angular/core';
import { BusinessDataDetails } from '../../models/child-models/business-data-details.model';
import { CommercialAddressDetails } from '../../models/child-models/commercial-address-details.model';
import { PriceJudgmentApproval } from '../../models/child-models/price-judgment-approval.model';
import { ProposalStatusModel } from 'src/app/shared/models/response/response-proposal-status.model';

@Component({
  selector: 'app-business-data-detail',
  templateUrl: './business-data-detail.component.html',
  styleUrls: ['./business-data-detail.component.scss'],
})
export class BusinessDataDetailComponent {
  @Input()
  public businessData: BusinessDataDetails;

  @Input()
  public commercialAddressDetails: CommercialAddressDetails;

  @Input()
  public proposalStatusModel: ProposalStatusModel;

  @Input()
  public priceJudgmentApproval: PriceJudgmentApproval[];

  constructor() {}

  isApprovalComercialStatus(): boolean {
    if (this.proposalStatusModel.statusCode == "35") {
      return true;
    }
    return false;
  }

  getPriceJudgmentApproval(approverTypeId: number): string {
    const judgment = this.priceJudgmentApproval?.find((j) => j.approverTypeId === approverTypeId);
    if (judgment?.judgment === true) {
      return 'Aprovado';
    } else if (judgment?.judgment === false) {
      return 'Recusado';
    } else {
      return 'Aguardando';
    }
  }
}
