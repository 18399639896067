<mat-card class="fd-business-data-detail__card-container" *ngIf="isApprovalComercialStatus()">
  <mat-card-title class="fd-business-data-detail__card-title">Aprovação de Alçada Comercial</mat-card-title>
  <ng-container>
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-business-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="25">
          <strong>Gerente</strong>
          <div>
            <span>{{ getPriceJudgmentApproval(1) }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Diretor</strong>
          <div>
            <span>{{ getPriceJudgmentApproval(2) }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Diretor Executivo</strong>
          <div>
            <span>{{ getPriceJudgmentApproval(3) }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>

<mat-card class="fd-business-data-detail__card-container" *ngIf="businessData">
  <mat-card-title class="fd-business-data-detail__card-title"
    >Dados do Negócio - {{ businessData?.proposalNumber }} - {{ businessData?.merchantId }}</mat-card-title
  >
  <ng-container>
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout="row" fxLayout.xs="column" fxFlexFill class="fd-business-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="12">
          <strong>CPF/CNPJ</strong>
          <div>
            <span>{{ businessData?.cpfCnpj | cpfCnpj }}</span>
          </div>
        </div>
        <div fxFlex="25" *ngIf="businessData?.socialReason">
          <strong>Razão Social</strong>
          <div>
            <span>{{ businessData?.socialReason }}</span>
          </div>
        </div>
        <div fxFlex="20">
          <strong>Nome Fantasia</strong>
          <div>
            <span>{{ businessData?.fantasyName }}</span>
          </div>
        </div>
        <div *ngIf="businessData?.foundationDate" fxFlex="10">
          <strong>Data da Fundação</strong>
          <div>
            <span>{{ businessData?.foundationDate }}</span>
          </div>
        </div>
        <div fxflexfill>
          <strong>CNAE</strong>
          <div>
            <span>{{ businessData?.cnae }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>

<mat-card class="fd-business-data-detail__card-container" *ngIf="commercialAddressDetails">
  <mat-card-title class="fd-business-data-detail__card-title">Endereço Comercial</mat-card-title>
  <ng-container>
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-business-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="12">
          <strong>CEP</strong>
          <div>
            <span>{{ commercialAddressDetails?.cep | cep }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Endereço</strong>
          <div>
            <span>{{ commercialAddressDetails?.address }}</span>
          </div>
        </div>
        <div fxFlex="5">
          <strong>Número</strong>
          <div>
            <span>{{ commercialAddressDetails?.number }}</span>
          </div>
        </div>
        <div fxFlex="15">
          <strong>Complemento</strong>
          <div>
            <span>{{ commercialAddressDetails?.complement }}</span>
          </div>
        </div>
        <div fxFlex="20">
          <strong>Bairro</strong>
          <div>
            <span>{{ commercialAddressDetails?.neighborhood }}</span>
          </div>
        </div>
        <div fxFlex="13">
          <strong>Cidade</strong>
          <div>
            <span>{{ commercialAddressDetails?.city }}</span>
          </div>
        </div>
        <div fxflexfill>
          <strong>UF</strong>
          <div>
            <span>{{ commercialAddressDetails?.uf }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>
