import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
  fas,
  faArrowLeft,
  faBars,
  faCamera,
  faChevronDown,
  faExclamationTriangle,
  faInfoCircle,
  faPencilAlt,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faTools,
  faTrash,
  faUserCircle,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FdButtonComponent } from './fd-form-components/fd-button/fd-button.component';
import { FdCheckboxComponent } from './fd-form-components/fd-checkbox/fd-checkbox.component';
import { FdConfirmComponent } from './fd-form-components/fd-confirm/fd-confirm.component';
import { FdCurrencyInputComponent, FdCurrencyInputConfig } from './fd-form-components/fd-currency-input/fd-currency-input.component';
import { FdDatepickerComponent, FdDatepickerConfig } from './fd-form-components/fd-datepicker/fd-datepicker.component';
import { FdFeeEditorComponent, FdFeeEditorConfig } from './fd-form-components/fd-fee-editor/fd-fee-editor.component';
import { FdInputComponent, FdInputConfig } from './fd-form-components/fd-input/fd-input.component';
import { FdLabelComponent, FdLabelConfig } from './fd-form-components/fd-label/fd-label.component';
import { FdOptionPickerComponent, FdOptionPickerConfig } from './fd-form-components/fd-option-picker/fd-option-picker.component';
import { FdProposalCardComponent } from './fd-form-components/fd-proposal-card/fd-proposal-card.component';
import { FdRadioButtonComponent, FdRadioButtonConfig } from './fd-form-components/fd-radio-button/fd-radio-button.component';
import { FdSearchBarComponent } from './fd-form-components/fd-search-bar/fd-search-bar.component';
import { FdSelectComponent, FdSelectConfig } from './fd-form-components/fd-select/fd-select.component';
import { FdTableComponent } from './fd-form-components/fd-table/fd-table.component';
import { FdUnfilledProposalCardComponent } from './fd-form-components/fd-unfilled-proposal-card/fd-unfilled-proposal-card.component';
import { FdValueEditorComponent, FdValueEditorConfig } from './fd-form-components/fd-value-editor/fd-value-editor.component';
import { HeaderComponent } from './header/header.component';
import { CepPipe } from './pipes/cep.pipe';
import { CpfCnpjPipe } from './pipes/cpf-cnpj.pipe';
import { PercentPipe } from './pipes/percent.pipe';
import { WeekDayPipe } from './pipes/week-day.pipe';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
import { FdPepComponent } from './fd-form-components/fd-pep/fd-pep.component';
import { FdNationalityComponent } from './fd-form-components/fd-nationality/fd-nationality.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FdCopyTextButtonComponent } from './fd-form-components/fd-copy-text-button/fd-copy-text-button.component';

export type FdFieldConfig =
  | FdInputConfig
  | FdSelectConfig
  | FdRadioButtonConfig
  | FdLabelConfig
  | FdOptionPickerConfig
  | FdFeeEditorConfig
  | FdDatepickerConfig
  | FdCurrencyInputConfig
  | FdValueEditorConfig;

export interface FdFieldConfigs {
  [key: string]: FdFieldConfig;
}

@NgModule({
  declarations: [
    FdInputComponent,
    FdSelectComponent,
    FdButtonComponent,
    FdRadioButtonComponent,
    FdLabelComponent,
    FdCheckboxComponent,
    FdOptionPickerComponent,
    FdValueEditorComponent,
    FdTableComponent,
    FdCurrencyInputComponent,
    HeaderComponent,
    FdFeeEditorComponent,
    FdProposalCardComponent,
    FdSearchBarComponent,
    FdDatepickerComponent,
    FdUnfilledProposalCardComponent,
    FdConfirmComponent,
    FdPepComponent,
    FdNationalityComponent,
    WeekDayPipe,
    CpfCnpjPipe,
    CepPipe,
    PercentPipe,
    PhoneMaskPipe,
    FdCopyTextButtonComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatMenuModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatSelectInfiniteScrollModule,
    NgxCurrencyModule,
    MatToolbarModule,
    FlexLayoutModule,
  ],
  exports: [
    FdInputComponent,
    FdButtonComponent,
    FdSelectComponent,
    FdLabelComponent,
    FdRadioButtonComponent,
    FdCheckboxComponent,
    FdOptionPickerComponent,
    FdValueEditorComponent,
    FdTableComponent,
    FdCurrencyInputComponent,
    HeaderComponent,
    FdProposalCardComponent,
    FdFeeEditorComponent,
    FdSearchBarComponent,
    FdDatepickerComponent,
    FdUnfilledProposalCardComponent,
    FdConfirmComponent,
    FdPepComponent,
    FdNationalityComponent,
    WeekDayPipe,
    CpfCnpjPipe,
    CepPipe,
    PercentPipe,
    PhoneMaskPipe,
    FdCopyTextButtonComponent,
  ],
  providers: [MatDatepickerModule, MatTooltipModule, DatePipe, DecimalPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faArrowLeft,
      faChevronDown,
      faCamera,
      faSearch,
      faUserCircle,
      faInfoCircle,
      faSortDown,
      faSignOutAlt,
      faBars,
      faExclamationTriangle,
      faPencilAlt,
      faTrash,
      faTools,
      faChevronRight
    );
  }
}
