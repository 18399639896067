import { Component, Input } from '@angular/core';
import { Messages } from 'src/app/order/messages/order.messages';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';

@Component({
  selector: 'fd-copy-text-button',
  templateUrl: './fd-copy-text-button.component.html',
  styleUrls: ['./fd-copy-text-button.component.scss'],
})
export class FdCopyTextButtonComponent {
  @Input() target: string;
  @Input() tooltipLabel: string;

  constructor(private readonly dialogService: DialogService) {}

  copyText() {
    navigator.clipboard.writeText(this.target);
    this.dialogService.openDialog(Messages.COPY_TEXT_SUCCESS);
  }
}
