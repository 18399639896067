<mat-card class="fd-business-data-detail__card-container" *ngIf="identifyInfo">
  <mat-card-title class="fd-business-data-detail__card-title">Identify</mat-card-title>
  <ng-container>
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout="row" fxLayout.xs="column" fxFlexFill class="fd-business-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="20">
          <strong>Data do envio do Link</strong>
          <div>
            <span>{{ identifyInfo.createdDate | date: 'dd/MM/yyyy' }}</span>
          </div>
        </div>
        <div fxFlex="20">
          <strong>Data de expiração</strong>
          <div>
            <span>{{ identifyInfo.expiration | date: 'dd/MM/yyyy' }}</span>
          </div>
        </div>
        <div fxFlex="80">
          <strong>Link</strong>
          <div>
            <a target="_blank" [href]="identifyInfo.url" style="margin-right: 10px">{{ identifyInfo.url }}</a>
            <fd-copy-text-button [target]="identifyInfo.url" tooltipLabel="Copiar link"></fd-copy-text-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>
