import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';

import { stateList } from 'src/app/order/utils/state-list';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { CampaignSimulatorFilterModel, CampaignSimulatorModel } from '../../../shared/models/CampaignSimulatorFilterModel';
import { CampaignSimulatorService } from '../../../order/services/external/campaign-simulator/CampaignSimulatorService';
import { RateDataDetails } from '../proposal-detail/models/child-models/rate-data-details.model';
import { ActivitiesStatusEnum } from '../../../shared/models/response/response-cnae.model';
import { Messages } from '../../../order/messages/order.messages';
import { ParamAvailableTechnologyDTO, TechnologyPriceDataModel } from './models/technology-price-data.model';
import { PrepaymentDataModel } from './models/prepayment-data.model';
import { FactorDataModel } from './models/factor-data.model';
import { startRoutingDefinitions } from '../../routing/start-routing-definitions';
import { RoutingService } from '../../../order/services/internal/routing/routing.service';
import { WelcomeService } from '../welcome/welcome.service';
import { ConfigurationService } from '../../services/configuration.service';
import { cpfCnpjMask } from 'src/app/order/masks/document-masks';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { BlackListModel } from 'src/app/shared/models/BlackList.model';
import { BlackListService } from 'src/app/order/services/external/black-list/black-list.service';
import { FieldTypeBlacklistEnum } from 'src/app/shared/models/response/response-black-list.model';
import { CNPJ_REGEX, CPF_REGEX } from 'src/app/shared/utils/utils';
import {ProposalService} from "../../../order/services/external/proposal/proposal.service";

@Component({
  selector: 'app-campaign-simulator',
  templateUrl: './campaign-simulator.component.html',
  styleUrls: ['./campaign-simulator.component.scss'],
})
export class CampaignSimulatorComponent implements OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get annualBillingValue() {
    return this.formControls.annualBillingValue.value;
  }

  get personType() {
    return this.formControls.personType.value;
  }

  get cnae() {
    return this.formControls.cnae.value;
  }

  get state() {
    return this.formControls.state.value;
  }

  get receivingDeadline() {
    return this.formControls.receivingDeadline.value;
  }

  get cnaeDropdown() {
    return this.fields.cnae as FdSelectConfig;
  }

  public formGroup: FormGroup;
  public formOfferSelection: FormGroup;
  public fields: FdFieldConfigs;
  public filter: CampaignSimulatorFilterModel = new CampaignSimulatorFilterModel();

  protected alive = true;
  public userData: UserDataModel;
  public campaignSimulation: CampaignSimulatorModel[] = [];
  public campaignSelected: CampaignSimulatorModel;
  public data: any[];
  public additionalInformationCnae: any;

  public rateData: RateDataDetails[] = [];
  public technologyData: TechnologyPriceDataModel[] = [];
  public prepaymentData: PrepaymentDataModel[] = [];
  public factorData: FactorDataModel[] = [];

  public viewControl = {
    clientInformation: true,
    feesData: false,
    factorTableData: false,
    advanceFeeTableData: false,
    offerTableData: false,
    campaignTableData: false,
  };

  public personTypes: Item[] = [
    {
      label: 'Pessoa Física',
      value: PersonTypeValues.PHYSICAL_PERSON,
    },
    {
      label: 'Pessoa Jurídica',
      value: PersonTypeValues.LEGAL_PERSON,
    },
  ];

  public technologyTypeList: Item[] = [
    {
      label: 'Cartão Presente (POS ou TEF)',
      value: ["POS", "TEF"],
    },
    {
      label: 'Cartão Não Presente (Link de Pagamento ou Ecommerce)',
      value: ["COM"],
    },
  ];

  public offerOptions: Item[];

  constructor(
    private formBuilder: FormBuilder,
    private campaignSimulatorService: CampaignSimulatorService,
    private cnaeService: CnaeService,
    protected dataStore: DataStore,
    protected dataStoreService: DataStoreService,
    protected dialogService: DialogService,
    private routingService: RoutingService,
    private welcomeService: WelcomeService,
    private configurationService: ConfigurationService,
    private blackListService: BlackListService,
    protected proposalService: ProposalService
  ) {}

  public ngOnInit() {
    this.userData = this.dataStoreService.getUserData();
    this.startForms();
    this.getAblePriceSimulator();
  }

  public getAblePriceSimulator() {
    this.configurationService.getAblePriceSimulator().subscribe((response) => {
      if (!response) {
        this.initialPage();
      }
    });
  }

  public isEmptyRates() {
    return !this.rateData || this.rateData.length === 0;
  }

  public isEmptyTechnology() {
    return !this.technologyData || this.technologyData.length === 0;
  }

  public isEmptyPrePayment() {
    return !this.prepaymentData || this.prepaymentData.length === 0;
  }

  public isEmptyFactor() {
    return !this.factorData || this.factorData.length === 0;
  }

  public get isAnticipationExternal(): boolean {
    return this.filter.external;
  }

  public isPf(): boolean {
    return this.personType === this.personTypes[0].value;
  }

  public startForms(): void {
    this.formGroup = this.formBuilder.group({
      cpfCnpj: ['', Validators.required],
      annualBillingValue: ['', Validators.required],
      personType: ['', Validators.required],
      cnae: ['', Validators.required],
      state: ['', Validators.required],
      technologyType: ['', Validators.required],
      chargeModel: ['', Validators.required],
    });

    this.formOfferSelection = this.formBuilder.group({
      offerOptions: [''],
    });

    this.fields = {
      cpfCnpj: {
        label: 'CPF/CNPJ',
        controlName: 'cpfCnpj',
        type: InputType.TEL,
        mask: cpfCnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CPF/CNPJ',
          invalidCnpj: 'CNPJ inválido',
          invalidCpf: 'CPF inválido',
        },
      },
      annualBillingValue: {
        label: 'Faturamento Anual',
        disabled: false,
        controlName: 'annualBillingValue',
        messages: {
          required: 'Informe o CNAE',
          invalid: 'CNAE inválido',
        },
      },
      cnae: {
        label: 'Selecione o CNAE',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        controlName: 'cnae',
        messages: {
          required: 'Informe o CNAE',
          invalid: 'CNAE inválido',
        },
      },
      personType: {
        label: 'Escolha o tipo de Pessoa',
        items: this.personTypes,
        controlName: 'personType',
        messages: {
          required: 'Informe o tipo de Pessoa',
          invalid: 'Informe o tipo de Pessoa',
        },
      },
      state: {
        label: 'Selecione o Estado (UF)',
        controlName: 'state',
        items: stateList,
        messages: {
          required: 'Selecione um estado',
          invalid: 'Seleção inválida',
        },
      },
      technologyType: {
        label: 'Selecione o tipo de tecnologia',
        controlName: 'technologyType',
        items: this.technologyTypeList,
        messages: {
          required: 'Selecione um tipo de tecnologia',
          invalid: 'Seleção inválida',
        },
      },
      chargeModel: {
        items: this.chargeModelOptions,
        controlName: 'chargeModel',
        messages: {
          required: 'Informe uma opção',
        },
      },
      offerOptions: {
        items: this.offerOptions,
        controlName: 'offerOptions',
        messages: {
          required: 'Informe uma opção',
        },
      },
    };
  }

  public clearForm() {
    this.formControls['cpfCnpj'].setValue('');
    this.formControls['cpfCnpj'].markAsPristine();
    this.formControls['cpfCnpj'].markAsUntouched();


    this.formControls['cnae'].setValue('');
    this.formControls['cnae'].markAsPristine();
    this.formControls['cnae'].markAsUntouched();

    this.formControls['annualBillingValue'].setValue(0);
    this.formControls['annualBillingValue'].markAsPristine();
    this.formControls['annualBillingValue'].markAsUntouched();

    this.formControls['state'].setValue('');
    this.formControls['state'].markAsPristine();
    this.formControls['state'].markAsUntouched();

    this.formControls['technologyType'].setValue('');
    this.formControls['technologyType'].markAsPristine();
    this.formControls['technologyType'].markAsUntouched();


  }

  public checkDuplicateProposals() {
    let cpfCnpj = this.formControls.cpfCnpj.value;
    let technologies = this.formControls.technologyType.value;
    if(cpfCnpj && technologies) {
      this.proposalService
        .findDuplicates(cpfCnpj, technologies)
        .toPromise()
        .then((resp) => {
          if (resp) {
            this.dialogService.openDialog(Messages.PROPOSAL_DUPLICITY_SIMULADOR_ERROR);
            this.clearForm();
          }
        })
    }
  }

  public async onCpfCnpjChange(document: string) {
    const personTypeValue = this.getPersonTypeByDocument(document);
    if (!personTypeValue) {
      return;
    }
    const personTypeFieldName = 'personType';
    this.formGroup.controls[personTypeFieldName].setValue(personTypeValue);
    this.callBlackListCpf(FieldTypeBlacklistEnum.DOCUMENT, document);
  }

  public async callBlackListCpf(field: string, value: string) {
    const userInfo = this.dataStoreService.getUserData();
    const blackListModel = new BlackListModel(userInfo.institution, userInfo.serviceContract.toString(), value, field);
    const isInBlacklist = await this.blackListService
      .validateBlackList(blackListModel)
      .pipe(takeWhile(() => this.alive))
      .toPromise();

    if (isInBlacklist) {
      this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED);
      this.clearForm();
    } else {
      this.checkDuplicateProposals();
    }

  }

  public getPersonTypeByDocument(document: string) {
    if (CPF_REGEX.test(document)) {
      return PersonTypeValues.PHYSICAL_PERSON;
    }
    if (CNPJ_REGEX.test(document)) {
      return PersonTypeValues.LEGAL_PERSON;
    }
    return null;
  }

  public changeOptionOutOf(event: any) {
    this.resetCampaign();
    this.filter.hasMdr = event == 'mdrWithoutAnticipation';
    this.filter.hasMdrFlex = event == 'mdrFlex2Days';
    this.filter.mdrWithPrepayment = event == 'mdrWithAnticipation' || event == 'mdrWithAnticipationAndExternalAnticipation';
    this.filter.external = event == 'mdrWithAnticipationAndExternalAnticipation';

    if (event == 'mdrFlex2Days') {
      this.filter.chargeModelEnum = 'MDR FLEX';
      this.filter.receivingDeadline = 2;
    } else {
      this.filter.chargeModelEnum = 'MDR';
      this.filter.receivingDeadline = undefined;
    }
  }

  public searchItems(): void {
    this.resetCampaign();
    this.filter.cnae = this.cnae;
    this.filter.annualBillingValue = this.annualBillingValue;
    this.filter.personType = this.personType;
    this.filter.uf = this.state;
    this.filter.mcc = this.additionalInformationCnae.additionalProperties.mcc;
    this.filter.cpfCnpj = '';

    this.campaignSimulatorService
      .getCampaignsByFilter(this.filter)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res) => {
          this.campaignSimulation = res;
          if (this.campaignSimulation.length > 0) {
            this.offerOptions = this.campaignSimulation.map((camp) => this.mapCampaigns(camp));
            (this.fields.offerOptions as FdSelectConfig).items = this.offerOptions;
            this.viewControl.campaignTableData = true;
          }
        },
        (err) => {
          this.dialogService.openDialogWithMessage(err.error.message);
          return;
        }
      );
  }

  public searchRates(): void {
    this.resetFees();

    switch (this.filter.chargeModelEnum) {
      case 'MDR':
        this.getMdrRates();
        break;
      case 'MDR FLEX':
        this.getMdrFlexRates();
        break;
    }
  }

  public getMdrRates() {
    if (this.filter.mdrWithPrepayment) {
      this.getMdrRatesWithPrepayment();
    } else {
      this.getMdrRatesWithoutPrepayment();
    }
  }

  public getMdrFlexRates() {
    let requests: any[] = [];
    requests.push(this.getTechnologiesPriceObservable());
    requests.push(this.getMdrFlexRatesObservable());
    requests.push(this.getFactorFlexObservable());

    this.rateData = [];
    this.technologyData = [];
    this.factorData = [];

    forkJoin(requests)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any[]) => {
          this.technologyData = res[0];
          this.rateData = res[1];
          this.factorData = res[2];
        },
        (err) => {
          this.dialogService.openDialogWithMessage(Messages.OFFER_NOT_FOUND.description);
          return;
        }
      );
  }

  public getMdrRatesWithPrepayment() {
    let requests: any[] = [];
    requests.push(this.getTechnologiesPriceObservable());
    requests.push(this.getMdrRatesWithoutPrepaymentObservable());
    requests.push(this.getPrePaymentsRateObservable());

    this.rateData = [];
    this.technologyData = [];
    this.prepaymentData = [];

    forkJoin(requests)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any[]) => {
          this.technologyData = res[0];
          this.rateData = res[1];
          this.prepaymentData = [res[2]];
        },
        (err) => {
          this.dialogService.openDialogWithMessage(Messages.OFFER_NOT_FOUND.description);
          return;
        }
      );
  }

  public getFactorFlexObservable() {
    let filterMdrFlex: CampaignSimulatorFilterModel = this.filter;
    filterMdrFlex.idCampaign = this.campaignSelected.idCampaign;
    filterMdrFlex.mcc = this.additionalInformationCnae.additionalProperties.mcc;
    filterMdrFlex.receivingDeadline = 2;
    return this.campaignSimulatorService.getFactorFlexRates(filterMdrFlex);
  }

  public getMdrFlexRatesObservable() {
    let filterMdrFlex: CampaignSimulatorFilterModel = this.filter;
    filterMdrFlex.idCampaign = this.campaignSelected.idCampaign;
    filterMdrFlex.mcc = this.additionalInformationCnae.additionalProperties.mcc;
    filterMdrFlex.receivingDeadline = 2;
    return this.campaignSimulatorService.getMdrFlexRates(filterMdrFlex);
  }

  public getMdrRatesWithoutPrepayment() {
    let requests: any[] = [];
    requests.push(this.getTechnologiesPriceObservable());
    requests.push(this.getMdrRatesWithoutPrepaymentObservable());
    this.rateData = [];
    this.technologyData = [];
    forkJoin(requests)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res: any[]) => {
          this.technologyData = [...res[0]];
          this.rateData = [...res[1]];
        },
        (err) => {
          this.dialogService.openDialogWithMessage(Messages.OFFER_NOT_FOUND.description);
          return;
        }
      );
  }

  public getMdrRatesWithoutPrepaymentObservable() {
    let filterMdrWithoutPrepayment: CampaignSimulatorFilterModel = this.filter;
    filterMdrWithoutPrepayment.idCampaign = this.campaignSelected.idCampaign;
    filterMdrWithoutPrepayment.mcc = this.additionalInformationCnae.additionalProperties.mcc;
    return this.campaignSimulatorService.getMdrRates(filterMdrWithoutPrepayment);
  }

  public getPrePaymentsRateObservable() {
    let filterPrePayment: CampaignSimulatorFilterModel = this.filter;
    filterPrePayment.idCampaign = this.campaignSelected.idCampaign;
    filterPrePayment.mcc = this.additionalInformationCnae.additionalProperties.mcc;
    return this.campaignSimulatorService.getPrepaymentRates(filterPrePayment);
  }

  public getTechnologiesPriceObservable() {
    let filterTechnology: ParamAvailableTechnologyDTO = new ParamAvailableTechnologyDTO();
    filterTechnology.personType = this.personType;
    filterTechnology.annualBillingValue = this.annualBillingValue;
    filterTechnology.idCampaign = this.campaignSelected.idCampaign;
    filterTechnology.mcc = this.additionalInformationCnae.additionalProperties.mcc;
    return this.campaignSimulatorService.getTechnologyPrice(filterTechnology);
  }

  public selectCnae(event) {
    if (event.value) {
      let additionalInformationCnae = this.cnaeDropdown.items.find((x) => x.value === parseInt(event.value, 10));
      if (additionalInformationCnae.additionalProperties.status === ActivitiesStatusEnum.UNWANTED) {
        this.formControls.cnae.setValue('');
        this.dialogService.openDialogWithHtmlMessageWithTitle(
          Messages.CNAE_INELEGIBLE.title,
          Messages.CNAE_INELEGIBLE.description + '<br><br><br><br>'
        );
        return;
      }
      this.additionalInformationCnae = additionalInformationCnae;
    }
  }

  public selectTypeTechnology() {
    this.checkDuplicateProposals();
  }

  public selectPersonType() {
    this.formControls.cnae.setValue('');
    this.cnaeDropdown.items = [];
  }

  public getCnaeList(filterValue) {
    if (!filterValue || !filterValue.length) {
      return;
    }

    if (filterValue.length < 3) {
      this.cnaeDropdown.items = [];
      return;
    }

    if (!isNaN(filterValue)) {
      filterValue = Number(filterValue);
    }

    this.cnaeService
      .getCnaeList(filterValue, this.isPf())
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (cnaeList) => {
          if (cnaeList !== null) {
            this.cnaeDropdown.items = [];
            this.cnaeDropdown.items.push(...cnaeList);
          }
        },
        () => {
          this.cnaeDropdown.items = [];
        }
      );
  }

  public onSelectRate(event): void {
    this.campaignSelected = this.campaignSimulation.find((c) => c.idCampaign == event);
    this.filter.idCampaign = this.campaignSelected.idCampaign;
    this.searchRates();
  }

  public resetCampaign(): void {
    this.campaignSelected = undefined;
    this.viewControl.campaignTableData = false;
    this.offerOptions = [];
    this.technologyData = [];
    this.rateData = [];
    this.prepaymentData = [];
    this.factorData = [];
    (this.fields.offerOptions as FdSelectConfig).items = this.offerOptions;
    this.formOfferSelection.controls.offerOptions.setValue(null);
    this.resetFees();
  }

  public resetFees(): void {
    this.viewControl.advanceFeeTableData = false;
    this.viewControl.factorTableData = false;
    this.viewControl.offerTableData = false;
  }

  public chargeModelOptions: Item[] = [
    {
      label: 'MDR com Antecipação',
      value: 'mdrWithAnticipation',
    },
    {
      label: 'MDR Com Antecipação e Agenda Externa',
      value: 'mdrWithAnticipationAndExternalAnticipation',
    },
    {
      label: 'MDR sem Antecipação',
      value: 'mdrWithoutAnticipation',
    },
    {
      label: 'MDR Flex - 2 dias',
      value: 'mdrFlex2Days',
    },
  ];

  public selectionChange(event: any) {
    this.filter.mdrWithPrepayment = event;
  }

  public mapCampaigns(camp): Item {
    return { label: camp.description, value: camp.idCampaign };
  }

  initialPage() {
    this.routingService.navigateWithParentTo(
      startRoutingDefinitions.start.routes.WELCOME.parentRoute,
      startRoutingDefinitions.start.routes.WELCOME.name
    );
  }

  newAccreditation() {
    this.welcomeService.newAccreditation();
  }

  public redirectToHome() {
    this.routingService.navigateToHome(false);
  }
}

enum PersonTypeValues {
  PHYSICAL_PERSON = 'physical person',
  LEGAL_PERSON = 'legal person',
}
